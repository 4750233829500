<template>
  <div class="p-3">
    <div class="p-3 pt-0">
      <div class="flex align-items-center gap-13">
        <h1 class="title-header">Financeiro</h1>
        <img
          class="pointer"
          src="../../assets/icons/view.png"
          alt=""
          v-if="view"
          v-tooltip.top="'Ocultar valores'"
          @click="view = !view"
        />
        <img
          class="no-view"
          src="../../assets/icons/noview.png"
          v-tooltip.top="'Mostrar valores'"
          alt=""
          @click="view = !view"
          v-else
        />
      </div>

      <div class="nav-bar">
        <ul>
          <li>Clínica Sodré</li>
          <!-- Status -->
          <div class="dropdown">
            <li class="no-radius border-right">
              Status
              <div class="icon-dropdown" />
            </li>
            <div class="filter-dropdown" v-if="status">
              <div class="element-dropdown" style="border: 1px solid #f2f2f2">
                <Checkbox
                  name="armando"
                  value="Chicago"
                  v-model="checkStatus.clinica_armando"
                />
                <label for="armando">Clínica Armando</label>
              </div>
              <div class="element-dropdown">
                <Checkbox
                  name="city"
                  value="Chicago"
                  v-model="checkStatus.clinica_saoluis"
                />
                <label for="">Clínica São Luiz</label>
              </div>
              <div class="element-dropdown">
                <Checkbox
                  name="city"
                  value="Chicago"
                  v-model="checkStatus.clinica_saopaulo"
                />
                <label for="">Clínica São Paulo</label>
              </div>
              <div class="element-dropdown">
                <Checkbox
                  name="city"
                  value="Chicago"
                  v-model="checkStatus.clinica_sodre"
                />
                <label for="">Clinica Sodré</label>
              </div>
              <div class="element-dropdown">
                <Button label="Filtrar" class="btn" @click="status = false" />
              </div>
            </div>
          </div>
          <!-- Serviços -->
          <div class="dropdown">
            <li class="no-radius border-right" @click="changeFilter('servicos')">
              Serviços
              <div class="icon-dropdown" />
            </li>
            <div class="filter-dropdown" v-if="servicos">
              <div class="element-dropdown" style="border: 1px solid #f2f2f2">
                <Checkbox
                  name="consulta"
                  :binary="true"
                  v-model="checkServicos.consulta"
                />
                <label for="consulta">Consulta</label>
              </div>
              <div class="element-dropdown" style="border: 1px solid #f2f2f2">
                <Checkbox
                  name="cirurgia"
                  :binary="true"
                  v-model="checkServicos.cirurgia"
                />
                <label for="cirurgia">Cirurgia</label>
              </div>
              <div class="element-dropdown" style="border: 1px solid #f2f2f2">
                <Checkbox
                  name="clinica_geral"
                  :binary="true"
                  v-model="checkServicos.clinica_geral"
                />
                <label for="clinica_geral">Clínica Geral</label>
              </div>
              <div class="element-dropdown" style="border: 1px solid #f2f2f2">
                <Checkbox
                  name="procedimento"
                  :binary="true"
                  v-model="checkServicos.procedimento"
                />
                <label for="procedimento">Procedimento</label>
              </div>

              <div class="element-dropdown">
                <Button label="Filtrar" class="btn" @click="servicos = false" />
              </div>
            </div>
          </div>
          <!-- Tipo -->
          <div class="dropdown">
            <li class="last-nav" @click="changeFilter('tipo')">
              Tipo
              <div class="icon-dropdown" />
            </li>
            <div class="filter-dropdown" v-if="tipo">
              <div class="element-dropdown" style="border: 1px solid #f2f2f2">
                <Checkbox
                  name="consulta"
                  :binary="true"
                  v-model="checkTipo.presencial"
                />
                <label for="consulta">Presencial</label>
              </div>
              <div class="element-dropdown" style="border: 1px solid #f2f2f2">
                <Checkbox
                  name="cirurgia"
                  :binary="true"
                  v-model="checkTipo.video_chamada"
                />
                <label for="cirurgia">Videochamada</label>
              </div>
              <div class="element-dropdown" style="border: 1px solid #f2f2f2">
                <Checkbox
                  name="clinica_geral"
                  :binary="true"
                  v-model="checkTipo.home_care"
                />
                <label for="clinica_geral">Homecare</label>
              </div>

              <div class="element-dropdown">
                <Button label="Filtrar" class="btn" @click="tipo = false" />
              </div>
            </div>
          </div>
        </ul>
        <img src="../../assets/images/data_picker.png" alt="" />
      </div>
      <div class="flex gap-13 mb-3">
        <p
          class="filter-p"
          v-if="checkServicos.consulta"
          @click="checkServicos.consulta = false"
        >
          consulta x
        </p>
        <p
          class="filter-p"
          v-if="checkServicos.cirurgia"
          @click="checkServicos.cirurgia = false"
        >
          cirurgia x
        </p>
        <p
          class="filter-p"
          v-if="checkServicos.clinica_geral"
          @click="checkServicos.clinica_geral = false"
        >
          clínica geral x
        </p>
        <p
          class="filter-p"
          v-if="checkServicos.procedimento"
          @click="checkServicos.procedimento = false"
        >
          procedimento x
        </p>
        <p
          class="filter-p"
          v-if="checkTipo.presencial"
          @click="checkTipo.presencial = false"
        >
          presencial x
        </p>
        <p
          class="filter-p"
          v-if="checkTipo.video_chamada"
          @click="checkTipo.video_chamada = false"
        >
          videochamada x
        </p>
        <p
          class="filter-p"
          v-if="checkTipo.home_care"
          @click="checkTipo.home_care = false"
        >
          homecare x
        </p>
      </div>
      <div class="list-info">
        <div class="info">
          <h1>Saldo Liberado</h1>
          <p>
            R$ <span v-if="view">35.000,00</span> <span v-else>--, --</span>
          </p>
        </div>
        <div class="info">
          <h1>Saldo Programado</h1>
          <p>
            R$ <span v-if="view">55.000,00</span> <span v-else>--, --</span>
          </p>
        </div>
        <div class="info">
          <h1>Saldo Bloqueado</h1>
          <p>
            R$ <span v-if="view">65.000,00</span> <span v-else>--, --</span>
          </p>
        </div>
      </div>

      <div class="content">
        <div class="p-24">
          <div class="flex justify-content-between">
            <h1>Faturamento</h1>
            <div class="flex gap-16">
              <div class="field-radiobutton" v-for:="opcao in listOptions">
                <RadioButton
                  :id="opcao"
                  :name="opcao"
                  :value="opcao"
                  v-model="option"
                />
                <label :for="opcao">{{ opcao }}</label>
              </div>
            </div>
          </div>
          <img
            src="../../assets/images/financial-view.png"
            style="width: 100%;"
            alt=""
            v-if="view"
          />
          <img style="width: 100%;" src="../../assets/images/financial-noview.png" alt="" v-else />
          <div class="flex justify-content-between">
            <h1>Extrato</h1>
            <div class="flex gap-16">
              <div class="field-radiobutton" v-for:="swt in listSwitchOption">
                <InputSwitch v-model="switchOption[swt.vmodel]" />
                <label for="taxas">{{ swt.label }}</label>
              </div>
            </div>
          </div>
          <Divider class="p-0 m-0 mb-1" />
          <div class="saldo-extrato">
            <p>Saldo:</p>
            <b
              >R$ <span v-if="view">10.600,00</span>
              <span v-else>--, --</span></b
            >
          </div>
          <Divider class="p-0 m-0 mt-1" />
        </div>

        <Table :view="view" />
      </div>
    </div>
  </div>
</template>

<script>
import { ref } from "vue";
import Table from "./Table.vue";
export default {
  components: { Table },
  setup() {
    const view = ref(true);
    const status = ref(false);
    const servicos = ref(false);
    const tipo = ref(false);
    const checkStatus = ref({
      clinica_armando: false,
      clinica_saoluis: false,
      clinica_saopaulo: false,
      clinica_sodre: false,
    });
    const checkServicos = ref({
      consulta: false,
      cirurgia: false,
      clinica_geral: false,
      procedimento: false,
    });
    const checkTipo = ref({
      presencial: false,
      video_chamada: false,
      home_care: false,
    });
    
    const option = ref("Liberado");
    const listOptions = ["Liberado", "Bloqueado", "Programado"];
    const switchOption = ref({ taxas: false, liquido: false });
    const listSwitchOption = [
      { label: "Taxas", vmodel: "taxas" },
      { label: "Líquido", vmodel: "liquido" },
    ];

    const changeFilter = filter => {
      if(filter === "servicos"){
        servicos.value = !servicos.value
        tipo.value = false;
        status.value = false;
      }else if(filter === "tipo"){
        tipo.value = !tipo.value;
        servicos.value = false;
        status.value = false;
      }
    }

    return {
      view,
      status,
      servicos,
      tipo,
      checkStatus,
      checkServicos,
      checkTipo,
      option,
      listOptions,
      switchOption,
      listSwitchOption,
      changeFilter
    };
  },
};
</script>

<style lang="scss" scoped>
.list-info {
  display: flex;
  width: 100%;
  min-height: 110px;
  gap: 20px;
  .info {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: calc(100% / 3);
    background: #fff;
    border-radius: 8px;
    padding: 0 30px;
    h1 {
      font-size: 20px;
      color: #a2c0d4;
      margin: 0;
      padding: 0;
    }
    p {
      font-size: 24px;
      font-weight: bold;
    }
  }
}
.nav-bar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  flex-wrap: wrap;
  margin-top: 36px;
  ul {
    display: flex;
    margin-left: 0px !important;
    padding: 0;
    margin: 0 !important;
    li {
      display: flex;
      align-items: center;
      gap: 10px;
      list-style-type: none;
      padding: 12px 30px;
      background: #fff;
      border-right: 2px solid #f1f5f8;
      color: #828282;
      font-weight: 700;
      max-height: 40px;
      cursor: pointer;
      &:first-child {
        border-top-left-radius: 8px;
        border-bottom-left-radius: 8px;
      }
      &:last-child {
        border: none;
        border-top-right-radius: 8px;
        border-bottom-right-radius: 8px;
      }
    }
  }
}

.content {
  position: relative;
  background: #fff;
  border-radius: 8px;
  margin-top: 19px;
  padding-bottom: 24px;
  h1 {
    color: #2d313d;
    font-weight: 700;
    margin: 0;
    font-size: 20px;
  }
  .p-24 {
    padding: 24px;
  }
}
.filter-p {
  width: max-content;
  border-radius: 8px;
  background: #fff;
  padding: 4px 8px;
  font-size: 12px;
  cursor: pointer;
}
.saldo-extrato {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 54px;
  background-color: rgb(111, 207, 151, 0.1);
  padding: 8px;
  p {
    color: #3d4766;
    margin: 0;
  }
  b {
    color: #333333;
  }
}
.dropdown {
  position: relative;
  display: flex;
  flex-direction: column;
  .element-dropdown:last-child{
    border-bottom-left-radius: 8px !important;
    border-bottom-right-radius: 8px !important;
  }
}
.filter-dropdown {
  width: max-content;
  background: #fff;
  z-index: 999;
  padding: 0;
  position: absolute;
  top: 40px;
}
.element-dropdown {
  display: flex;
  align-items: center;
  padding: 12px !important;
  border: 1px solid #f2f2f2;
  label {
    margin-left: 8px;
  }
}
.no-radius {
  border-top-left-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
  border-top-right-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
}
.last-nav{
  border-top-left-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
  border-top-right-radius: 8px !important;
  border-bottom-right-radius: 8px !important;
}
.border-right {
  border-right: 2px solid #f1f5f8 !important;
}
.gap-13 {
  gap: 13px;
}
.gap-16 {
  gap: 16px;
}
.pointer {
  cursor: pointer;
}
.no-view {
  cursor: pointer;
  filter: invert(57%) sepia(68%) saturate(3415%) hue-rotate(338deg)
    brightness(100%) contrast(101%);
}
.btn {
  height: 40px;
}
</style>

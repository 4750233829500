<template>
  <h1 class="plr-24">28/02/2021</h1>
  <div class="table-light">
    <table class="transaction-data">
      <tr>
        <th>#4326</th>
        <th>12:23</th>
        <th><b>Resgate</b></th>
        <th></th>
        <th class="concluido">Concluido</th>
      </tr>
    </table>
    <table class="balance-values">
      <tr>
        <th class="font-bold grayish-blue">
          R$ <span v-if="view">300,00</span> <span v-else>--, --</span>
        </th>
        <th class="font-bold saldo-negativo">
          - R$ <span v-if="view">30.000,00</span> <span v-else>--, --</span>
        </th>
      </tr>
    </table>
  </div>
  <div class="table-light" @click="modalCard = true" style="cursor: pointer;">
    <table class="transaction-data">
      <tr>
        <th>#4325</th>
        <th>11:15</th>
        <th class="font-bold">Clínica Sodré</th>
        <th>Oscar Watanabe</th>
        <th class="concluido">Concluido</th>
      </tr>
    </table>
    <table class="balance-values">
      <tr>
        <th class="font-bold grayish-blue">
          R$ <span v-if="view">30,00</span> <span v-else>--, --</span>
        </th>
        <th class="font-bold">
          R$ <span v-if="view">300,00</span> <span v-else>--, --</span>
        </th>
      </tr>
    </table>
  </div>
  <div class="table-gray">
    <table class="transaction-data">
      <tr>
        <th>#4324</th>
        <th>10:07</th>
        <th class="font-bold">Clínica São Luiz</th>
        <th>Eric Fontes</th>
        <th class="concluido">Concluido</th>
      </tr>
    </table>
    <table class="balance-values">
      <tr>
        <th class="font-bold grayish-blue">
          R$ <span v-if="view">30,00</span> <span v-else>--, --</span>
        </th>
        <th class="font-bold">
          R$ <span v-if="view">300,00</span> <span v-else>--, --</span>
        </th>
      </tr>
    </table>
  </div>
  <div class="flex justify-content-between plr-24 mt-13">
    <h1>26/02/2021</h1>
    <p class="font-bold grayish-blue">
      Saldo R$ <span v-if="view">45.000,00</span> <span v-else>--, --</span>
    </p>
  </div>
  <div class="table-gray">
    <table class="transaction-data">
      <tr>
        <th>#4323</th>
        <th>12:00</th>
        <th class="font-bold">Clínica São Paulo</th>
        <th>Paulo Afonso</th>
        <th class="concluido">Concluido</th>
      </tr>
    </table>
    <table class="balance-values">
      <tr>
        <th class="font-bold grayish-blue">
          R$ <span v-if="view">30,00</span> <span v-else>--, --</span>
        </th>
        <th class="font-bold">
          R$ <span v-if="view">300,00</span> <span v-else>--, --</span>
        </th>
      </tr>
    </table>
  </div>
  <div class="table-light">
    <table class="transaction-data">
      <tr>
        <th>#4322</th>
        <th>11:00</th>
        <th class="font-bold">Clínica Sodré</th>
        <th>Maria Gabriela</th>
        <th class="concluido">Concluido</th>
      </tr>
    </table>
    <table class="balance-values">
      <tr>
        <th class="font-bold grayish-blue">
          R$ <span v-if="view">30,00</span> <span v-else>--, --</span>
        </th>
        <th class="font-bold">
          R$ <span v-if="view">300,00</span> <span v-else>--, --</span>
        </th>
      </tr>
    </table>
  </div>
  <div class="table-gray">
    <table class="transaction-data">
      <tr>
        <th>#4321</th>
        <th>10:00</th>
        <th class="font-bold">Clínica Armando</th>
        <th>Ana Paula</th>
        <th class="concluido">Concluido</th>
      </tr>
    </table>
    <table class="balance-values">
      <tr>
        <th class="font-bold grayish-blue">
          R$ <span v-if="view">30,00</span> <span v-else>--, --</span>
        </th>
        <th class="font-bold">
          R$ <span v-if="view">300,00</span> <span v-else>--, --</span>
        </th>
      </tr>
    </table>
  </div>

  <div class="load-more">Carregar mais (10 registros)</div>
  <div class="flex justify-content-end gap-8 plr-24">
    <Button class="w-max h-40" label="Resgatar" @click="modalRedeem = true" />
    <Button class="w-max h-40 no-bg" label="Configurar conta" />
  </div>

  <Dialog
    class="modal"
    v-model:visible="modalRedeem"
    :closable="false"
    showheader="false"
    :breakpoints="{
      '960px': '75vw',
      borderRadius: '8px',
    }"
    :style="{
      width: '402px',
      borderRadius: '8px',
      boxShadow: '0px 0px 16px rgba(61, 71, 102, 0.08)',
      backdropFilter: 'blur(8px)',
    }"
  >
    <template #header>
      <h5 class="mb-0 text-center">Resgate</h5>
    </template>

    <div class="flex justify-content-between p-content">
      <p class="font-bold">Valor Liberado:</p>
      <p class="font-bold saldo-negativo">R$: 35.000,00</p>
    </div>
    <Divider class="my-2" />
    <div class="flex justify-content-between align-items-center">
      <p class="font-bold">Valor para resgate:</p>
      <InputNumber
        id="currency-us"
        class="input-text-right"
        v-model="valuemodal"
        mode="currency"
        currency="BRL"
        locale="pt-BR"
        max="35000"
      />
    </div>
    <Divider class="my-2" />
    <div class="flex justify-content-between">
      <p class="font-bold">Data para o depósito:</p>
      <p>05/05/2022</p>
    </div>
    <Divider class="my-2" />
    <div class="flex justify-content-between">
      <p class="font-bold">Taxa (10%):</p>
      <p class="font-bold">R$ 3.500,00</p>
    </div>
    <Divider class="my-2" />
    <div class="flex justify-content-between">
      <p class="font-bold">Forma do depósito:</p>
      <p>Transferência bancária</p>
    </div>
    <Divider class="my-2" />
    <div class="flex justify-content-between">
      <p class="font-bold">Valor final:</p>
      <p class="font-bold saldo-negativo">R$ 31.500,00</p>
    </div>
    <Divider class="my-2" />
    <div class="flex justify-content-between">
      <p class="font-bold">ID da solicitação:</p>
      <p>23A45R34255T345344RF64Q</p>
    </div>

    <template #footer>
      <div class="flex justify-content-end align-items-center h-full">
        <Button
          label="Finalizar"
          class="btn-confirm w-max"
          style="height: 40px"
          @click="modalRedeem = false"
        />
      </div>
    </template>
  </Dialog>
  <Dialog
    class="modal"
    v-model:visible="modalCard"
    :closable="false"
    showheader="false"
    :breakpoints="{
      '960px': '75vw',
      borderRadius: '8px',
    }"
    :style="{
      width: '402px',
      borderRadius: '8px',
      boxShadow: '0px 0px 16px rgba(61, 71, 102, 0.08)',
      backdropFilter: 'blur(8px)',
    }"
  >
    <template #header>
      <div class="flex flex-column">
        <h5 style="font-size: 14px;" class="mb-0 text-center">COMPROVANTE</h5>
        <p style="font-size: 12px;">#4321</p>
      </div>
    </template>

    <div class="flex justify-content-between p-content">
      <p class="font-bold">Paciente</p>
      <p class="font-bold saldo-negativo">Oscar Watanabe</p>
    </div>
    <Divider class="my-2" />
    <div class="flex justify-content-between">
      <p class="font-bold">Local</p>
      <p class="font-bold saldo-negativo">Clínica Sodré</p>
    </div>
    <Divider class="my-2" />
    <div class="flex justify-content-between">
      <p class="font-bold">Forma de Pagamento</p>
      <p>PIX</p>
    </div>
    <Divider class="my-2" />
    <div class="flex justify-content-between">
      <p class="font-bold">Data do pagamento:</p>
      <p>05/10/2021</p>
    </div>
    <Divider class="my-2" />
    <div class="flex justify-content-between">
      <p class="font-bold">Serviço:</p>
      <p>Consulta</p>
    </div>
    <Divider class="my-2" />
    <div class="flex justify-content-between">
      <p class="font-bold">Tipo de Serviço</p>
      <p>Presencial</p>
    </div>
    <Divider class="my-2" />
    <div class="flex justify-content-between">
      <p class="font-bold">Valor:</p>
      <p>R$ 270,00</p>
    </div>
    <Divider class="my-2" />
    <div class="flex justify-content-between">
      <p class="font-bold">Taxa:</p>
      <p>R$ 30,00</p>
    </div>
    <Divider class="my-2" />
    <div class="flex justify-content-between">
      <p class="font-bold">Valor final:</p>
      <p class="font-bold">R$ 300,00</p>
    </div>
    <Divider class="my-2" />
    <div class="flex justify-content-between">
      <p class="font-bold">ID da transação</p>
      <p class="font-bold">23A45R34255T345344RF64Q</p>
    </div>
  </Dialog>
</template>

<script>
import { ref } from "vue";
export default {
  props: ["view"],
  setup() {
    const modalRedeem = ref(false);
    const modalCard = ref(false);
    const valuemodal = ref(35000);
    return {
      modalRedeem,
      modalCard,
      valuemodal,
    };
  },
};
</script>

<style lang="scss" scoped>
.table-light {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 25px;
  background-color: rgb(241, 245, 248, 0.5);
  min-height: 39px;
  margin-bottom: 2px;
  max-width: 100% !important;
  overflow-x: hidden;
  th {
    font-weight: normal;
    border: none !important;
  }
  .concluido {
    color: #27ae60;
  }
}
h1 {
  color: #2d313d;
  font-weight: 700;
  margin: 0;
  margin-bottom: 8px;
  font-size: 20px;
}
.table-gray {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 25px;
  background-color: rgb(242, 242, 242, 0.5);
  min-height: 39px;
  margin-bottom: 2px;
  max-width: 100% !important;
  overflow-x: hidden;
  th {
    font-weight: normal;
    border: none !important;
  }
  .concluido {
    color: #27ae60;
  }
}
.balance-values {
  min-width: 210px;
  tr {
    width: 100%;
  }
  th {
    width: calc(100% / 2);
    &:first-child {
      text-align: left;
    }
    &:last-child {
      text-align: right;
    }
  }
}
.transaction-data {
  min-width: 400px;
  tr {
    width: 100%;
  }
  th {
    text-align: left;
    &:last-child {
      text-align: right;
    }
    &:nth-child(1) {
      width: 50px;
    }
    &:nth-child(2) {
      width: 50px;
    }
  }
}
.load-more {
  width: max-content;
  padding: 12px 36px;
  border: 1px solid #bdbdbd;
  color: #bdbdbd;
  font-weight: bold;
  border-radius: 8px;
  margin: 8px auto;
  cursor: pointer;
}
.saldo-negativo {
  color: #ff6a33;
}
.grayish-blue {
  color: #a2c0d4;
}
.plr-24 {
  padding: 0 24px !important;
}
.h-40 {
  height: 40px !important;
}
.gap-8 {
  gap: 8px;
}
.no-bg {
  background: #fff;
  color: #ff4500;
  &:hover {
    background: #fff !important;
    color: #ff4500 !important;
  }
}
.mt-13 {
  margin-top: 13px;
}
h1 {
  font-size: 14px;
}
.p-content {
  padding-top: 1.5rem;
}
</style>